import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";
import SidebarWithHeaderLayout from "../layouts/SideBarWithHeaderLayout";
import SuspenseLoader from "../componets/SuspenseLoader";
import QueryPlaygroundPage from "../pages/QueryPlayground";

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Home = Loader(lazy(() => import("../pages/home")));
const SeedConfigurator = Loader(
  lazy(() => import("../pages/SeedConfigurator"))
);
const Migration = Loader(lazy(() => import("../pages/Migration")));
const VisualView = Loader(lazy(() => import("../pages/VisualView")));
const AliasView = Loader(lazy(() => import("../pages/AliasView")));
const KPIBuilder = Loader(lazy(() => import("../pages/KPIBuilder")));
const KPIListView = Loader(
  lazy(() => import("../pages/KPIBuilder/KPIListView"))
);
const DashboardPage = Loader(lazy(() => import("../pages/DashboardBuilder")));
const AssetOnboardingPage = Loader(
  lazy(() => import("../pages/AssetOnboarding"))
);
const Unauthorized = Loader(lazy(() => import("../pages/Unauthorized")));

const adminRoutes: RouteObject[] = [
  {
    path: "/",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "seedconfigurator",
        element: <SeedConfigurator />,
      },
      {
        path: "migration",
        element: <Migration />,
      },
      {
        path: "aliasview",
        element: <AliasView />,
      },
      {
        path: "visualview",
        element: <VisualView />,
      },
      {
        path: "queryplayground",
        element: <QueryPlaygroundPage />,
      },
      {
        path: "kpibuilder/list",
        element: <KPIListView />,
      },
      {
        path: "kpibuilder",
        element: <KPIBuilder />,
      },
      {
        path: "dashboardbuilder",
        element: <DashboardPage />,
      },
      {
        path: "usermanagement",
        // element: <Usecase />,
      },
      {
        path: "roles",
        // element: <Usecase />,
      },
      {
        path: "asset-onboarding",
        element: <AssetOnboardingPage />,
      },
      {
        path: "",
        element: <Home />,
      },
    ],
  },
];

//creating new routes array for Developer role

const developerRoutes: RouteObject[] = [
  {
    path: "/",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "seedconfigurator",
        element: <SeedConfigurator />,
      },
      {
        path: "aliasview",
        element: <AliasView />,
      },
      {
        path: "visualview",
        element: <VisualView />,
      },
      {
        path: "queryplayground",
        element: <QueryPlaygroundPage />,
      },
      {
        path: "kpibuilder/list",
        element: <KPIListView />,
      },
      {
        path: "kpibuilder",
        element: <KPIBuilder />,
      },
      {
        path: "dashboardbuilder",
        element: <DashboardPage />,
      },
      {
        path: "asset-onboarding",
        element: <AssetOnboardingPage />,
      },
      {
        path: "",
        element: <Home />,
      },
    ],
  },
];

//creating new routes array for plant manager role
const plantManagerRoutes: RouteObject[] = [
  {
    path: "/",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "seedconfigurator",
        element: <SeedConfigurator />,
      },
      {
        path: "aliasview",
        element: <AliasView />,
      },
      {
        path: "visualview",
        element: <VisualView />,
      },
      {
        path: "queryplayground",
        element: <QueryPlaygroundPage />,
      },
      {
        path: "kpibuilder/list",
        element: <KPIListView />,
      },
      {
        path: "kpibuilder",
        element: <KPIBuilder />,
      },
      {
        path: "dashboardbuilder",
        element: <DashboardPage />,
      },
      {
        path: "asset-onboarding",
        element: <AssetOnboardingPage />,
      },
      {
        path: "",
        element: <Home />,
      },
    ],
  },
];

const unauthorizedRoutes: RouteObject[] = [
  {
    path: "/",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Unauthorized />,
      },

      {
        path: "seedconfigurator",
        element: <Unauthorized />,
      },
      {
        path: "aliasview",
        element: <Unauthorized />,
      },
      {
        path: "visualview",
        element: <Unauthorized />,
      },
      {
        path: "queryplayground",
        element: <Unauthorized />,
      },
      {
        path: "kpibuilder/list",
        element: <Unauthorized />,
      },
      {
        path: "kpibuilder",
        element: <Unauthorized />,
      },
      {
        path: "dashboardbuilder",
        element: <Unauthorized />,
      },
      {
        path: "asset-onboarding",
        element: <Unauthorized />,
      },
      {
        path: "usermanagement",
        // element: <Usecase />,
      },
      {
        path: "roles",
        // element: <Usecase />,
      },
    ],
  },
];

export { adminRoutes, unauthorizedRoutes, plantManagerRoutes, developerRoutes };
